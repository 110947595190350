import * as Yup from "yup"

const validationSchema = Yup.object().shape({
  naam: Yup.string()
    .max(50, "Het aantal karakters in uw naam dient kleiner te zijn dan 50")
    .required("Naam is verplicht"),
  email: Yup.string()
    .email("Geen geldig e-mail adres")
    .required("E-mail is verplicht"),
  bericht: Yup.string().required("Bericht is verplicht"),
})

export default validationSchema
