import React, { Component } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import striptags from "striptags"
import styled from "@emotion/styled"
import Video from "./video"
import globalStyles from "../../global-styles"

const CarouselCardComponentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`

const CarouselCardWrapper = styled.div`
  display: block;
  position: relative;
`
const CaptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: ${globalStyles.colors.black};
  color: ${globalStyles.colors.white};

  .caption {
    margin: 0 0.3rem;

    p {
      text-align: center;
    }
  }
`
const ImageWrapper = styled.div`
  display: block;
  position: relative;
`

class CarouselCard extends Component {
  state = {
    resized: false,
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  componentDidUpdate() {
    if (this.state.resized === true) {
      this.props.getSize(this.elementRef.getBoundingClientRect())
      this.setState({
        resized: false,
      })
    }
  }

  resize() {
    this.setState({
      resized: true,
    })
  }

  refCallback = element => {
    if (element) {
      this.elementRef = element
      this.props.getSize(element.getBoundingClientRect())
    }
  }

  render() {
    const { item, caption } = this.props
    return (
      <CarouselCardComponentWrapper ref={this.refCallback}>
        <CarouselCardWrapper>
          {(striptags(caption) !== "" || caption !== null) && (
            <CaptionWrapper>
              <span
                dangerouslySetInnerHTML={{
                  __html: caption,
                }}
              />
            </CaptionWrapper>
          )}

          {(() => {
            if (item.image.localFile != null) {
              return (
                <ImageWrapper>
                  <Img
                    alt={item.image.alt}
                    fluid={item.image.localFile.childImageSharp.fluid}
                  />
                </ImageWrapper>
              )
            } else if (item.video.embed_url != null) {
              return (
                <Video videoSrcURL="https://www.youtube.com/embed/-xHV0YxLnKc" />
              )
            }
          })()}
        </CarouselCardWrapper>
      </CarouselCardComponentWrapper>
    )
  }
}

CarouselCard.propTypes = {
  item: PropTypes.object.isRequired,
  caption: PropTypes.string,
}

export default CarouselCard
