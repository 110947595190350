import React, { useState } from "react"
import { Formik, Field } from "formik"
import axios from "axios"
import validationSchema from "./validation"
import { setupAxiosOptions } from "../form-functions"
import {
  FormFeedbackWrapper,
  FormSection,
  FormWrapper,
  FormElementWrapper,
  FormValidationElement,
} from "../form-styles"

const ContactForm = () => {
  const [feedbackMsg, setFeedBackMsg] = useState()
  const [feedbackStatus, setFeedBackStatus] = useState()

  return (
    <FormSection>
      {feedbackMsg && (
        <FormFeedbackWrapper>
          <p className={feedbackStatus}>{feedbackMsg}</p>
        </FormFeedbackWrapper>
      )}
      <Formik
        initialValues={{
          formName: "contact",
          botField: "",
          naam: "",
          email: "",
          bericht: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          axios(setupAxiosOptions(values))
            .then(response => {
              setFeedBackMsg(
                "Bedankt voor uw bericht we nemen zo spoedig mogelijk contact op!"
              )
              setFeedBackStatus("success")
              resetForm()
              setSubmitting(false)
            })
            .catch(err => {
              setFeedBackMsg(
                "Onze excuses, er is een probleem opgetreden bij het verzenden van het formulier. Indien dit probleem zich blijft voordoen neem gerust telefonisch contact op via het nummer 09/357 40 40."
              )
              setFeedBackStatus("error")
              setSubmitting(false)
            })
        }}
        render={({ touched, errors, isSubmitting, handleSubmit }) => (
          <FormWrapper
            name="contact"
            onSubmit={handleSubmit}
            data-netlify="true"
            data-netlify-honeypot="botField"
          >
            <Field type="hidden" name="botField" />
            <FormElementWrapper>
              <label htmlFor="naam">Naam</label>
              <Field type="text" name="naam" placeholder="naam" />
              {touched.naam && errors.naam && (
                <FormValidationElement>{errors.naam}</FormValidationElement>
              )}
            </FormElementWrapper>
            <FormElementWrapper>
              <label htmlFor="email">E-mail</label>
              <Field type="text" name="email" placeholder="e-mail" />
              {touched.email && errors.email && (
                <FormValidationElement>{errors.email}</FormValidationElement>
              )}
            </FormElementWrapper>
            <FormElementWrapper>
              <label htmlFor="bericht">Bericht</label>
              <Field
                name="bericht"
                component="textarea"
                rows="6"
                placeholder="Bericht"
              />
              {touched.bericht && errors.bericht && (
                <FormValidationElement>{errors.bericht}</FormValidationElement>
              )}
            </FormElementWrapper>
            <FormElementWrapper>
              <button type="submit" disabled={isSubmitting}>
                Verzenden
              </button>
            </FormElementWrapper>
          </FormWrapper>
        )}
      />
    </FormSection>
  )
}

export default ContactForm
