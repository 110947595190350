import * as qs from "query-string"
import kebabCase from "lodash.kebabcase"

const encodeRequestBody = data => {
  const formData = {}
  Object.keys(data).map(key => (formData[kebabCase(key)] = data[key]))
  return qs.stringify(formData)
}

const setupAxiosOptions = (values, url = "/") => {
  return {
    url: url,
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: encodeRequestBody(values),
  }
}

export { setupAxiosOptions }
