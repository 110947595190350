import React from "react"
import { graphql } from "gatsby"
import Head from "../components/metadata/head"
import SEO from "../components/metadata/seo"
import SpaZone from "../components/spa-zone"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { PageElementsFragment } from "../fragments/page/page-elements"

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
`

const Homepage = props => {
  const {
    data: {
      singlepage: { data: singlepageData },
    },
    data: {
      singlepage: {
        data: {
          body: [{ items }],
        },
      },
    },
  } = props

  const spaZones = items.map(item => {
    const {
      content_relation: {
        uid,
        id,
        document: [
          {
            data: { title, body: slices, page_theme: pageTheme },
          },
        ],
      },
    } = item
    return (
      <SpaZone
        location={props.location}
        key={uid}
        uid={uid}
        pageTitle={title.text}
        pageTheme={pageTheme}
        allSlices={slices}
      />
    )
  })

  return (
    <Layout>
      <SEO />
      <Head
        title={singlepageData.title.text}
        tagline={singlepageData.tagline.text}
      />
      <ContentWrapper>{spaZones}</ContentWrapper>
    </Layout>
  )
}

export default Homepage

export const pageQuery = graphql`
  query HomepageQuery {
    singlepage: prismicSinglePage {
      ...PageElementsFragment
    }
  }
`
