import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import globalStyles from "../../global-styles"
import CarouselCard from "./carousel-card"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import striptags from "striptags"
import Text from "./text"
import IconArrow from "../icons/icon-arrow"

const CarouselComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ carouselVariant }) => {
    let style = ``
    if (carouselVariant === "block-1x1" || carouselVariant === "block-3x1") {
      style += `margin-top: ${globalStyles.grid.marginBase};`
    }
    return style
  }}

  ${globalStyles.mq[1]} {
    ${({ carouselVariant }) => {
      let style = ``
      if (carouselVariant === "block-1x1") {
        style += `margin-right: ${globalStyles.grid.marginBase};`
      }
      if (carouselVariant === "block-3x1") {
        style += `flex-direction: row;`
      }
      return style
    }}
  }
`

const CarouselWrapper = styled.div`
	position: relative;
  	
	/* CSS Transitions */
	.carousel-appear{
		opacity: 0;
	}

	.carousel-appear.carousel-appear-active{
		opacity: 1;
		transition: opacity 500ms linear;
	}
	
	.carousel-exit {
		opacity: 1;
	}

	.carousel-exit.carousel-exit-active {
		opacity: 0;
		transition: opacity 500ms linear;
	}

	.carousel-exit-done {
		opacity: 0;
	}

	.carousel-enter {
		opacity: 0;
	}

	.carousel-enter.carousel-enter-active {
		opacity: 1;
		transition: opacity 500ms linear;
	}
	/* End - CSS Transitions */


	${globalStyles.mq[1]} {
		${({ carouselVariant }) =>
      carouselVariant === "default-colored" &&
      `margin-left: 9rem;
			margin-right: 9rem;
			top: -12rem;
			`}

		${({ carouselVariant }) => {
      if (carouselVariant === "block-3x1") {
        return `flex-basis: 66%;`
      }
    }}

    ${({ carouselVariant, htmlTextAlign }) => {
      if (carouselVariant === "block-3x1" && htmlTextAlign === "right") {
        return `order: -1;`
      }
    }}
		
	}
`

const CarouselNavigationButton = styled.button`
  background-color: transparent;
  position: absolute;
  border: none;
  top: calc(50% - 20px);
  z-index: 10;
  opacity: 0.7;
  cursor: pointer;

  &.prev {
    left: 1.5rem;
  }

  &.next {
    right: 1.5rem;
  }

  &:hover {
    opacity: 1;
  }

  &:disabled {
    display: none;
  }
`

const CarouselTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${globalStyles.colors.black};
  color: ${globalStyles.colors.white};

  ${({ carouselVariant }) =>
    (carouselVariant === "block-1x1" || carouselVariant === "block-3x1") &&
    `
    text-align: center;
    `}

  ${globalStyles.mq[1]} {
    ${({ carouselVariant }) =>
      (carouselVariant === "default" ||
        carouselVariant === "default-colored") &&
      `padding: 1rem 6rem 12rem 6rem;`}

    ${({ carouselVariant }) =>
      carouselVariant === "block-3x1" &&
      `
        flex-basis: 33%;
        `}
  }
`

class ImageCaroussel extends Component {
  state = {
    currentIndex: 0,
    size: {},
  }

  nextItem = () => {
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
    }))
  }

  prevItem = () => {
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - 1,
    }))
  }

  render() {
    const pageTheme = this.props.pageTheme
    const carouselVariant = this.props.carouselVariant
    const items = this.props.items
    const caption = this.props.caption
    const htmlText = this.props.htmlText
    const htmlTextAlign = this.props.htmlTextAlign
    const { currentIndex, size } = this.state
    const item = items[currentIndex]

    return (
      <CarouselComponentWrapper
        carouselVariant={carouselVariant}
        htmlTextAlign={htmlTextAlign}
      >
        {(striptags(htmlText) !== "" || htmlText !== null) && (
          <CarouselTextWrapper
            carouselVariant={carouselVariant}
            className={pageTheme}
          >
            <Text pageTheme={pageTheme} htmlText={htmlText} />
          </CarouselTextWrapper>
        )}
        <CarouselWrapper
          css={css`
            min-height: ${size.height}px;
          `}
          carouselVariant={carouselVariant}
          htmlTextAlign={htmlTextAlign}
        >
          <CarouselNavigationButton
            className="prev"
            onClick={() => this.prevItem()}
            disabled={this.state.currentIndex === 0}
          >
            <IconArrow width="40" direction="left" />
          </CarouselNavigationButton>
          <CarouselNavigationButton
            className="next"
            onClick={() => this.nextItem()}
            disabled={this.state.currentIndex === items.length - 1}
          >
            <IconArrow width="40" />
          </CarouselNavigationButton>
          <TransitionGroup>
            <CSSTransition
              key={currentIndex}
              in={true}
              appear={true}
              timeout={500}
              classNames="carousel"
            >
              <CarouselCard
                item={item}
                pageTheme={pageTheme}
                caption={caption}
                getSize={size => this.setState({ size })}
              />
            </CSSTransition>
          </TransitionGroup>
        </CarouselWrapper>
      </CarouselComponentWrapper>
    )
  }
}

export default ImageCaroussel

ImageCaroussel.propTypes = {
  items: PropTypes.array.isRequired,
  caption: PropTypes.string,
  htmlText: PropTypes.string,
  pageTheme: PropTypes.string,
  carouselVariant: PropTypes.string.isRequired,
  htmlTextAlign: PropTypes.string,
}
