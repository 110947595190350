import React from "react"
import SliceZone from "./slice-zone"
import PropTypes from "prop-types"
import changeCase from "change-case"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import globalStyles from "../global-styles"
import ContactForm from "../forms/contact/form"
import OrderForm from "../forms/order/form"
import HomepageFooter from "./homepage-footer"

const SpaZoneSection = styled.section`
  margin-bottom: 5rem;

  ${globalStyles.mq[1]} {
    margin-bottom: 8rem;
  }
`

const SliceZonesWrapperBaseMobileStyle = css`
  position: relative;
  display: block;

  aside {
    margin: 0 ${globalStyles.grid.marginBase};
  }
`

const SliceZonesWrapperBaseTableLandscapeStyle = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${globalStyles.grid.containerStyle};

  aside {
    max-width: 20vw;
  }
`

/* HOMEPAGE THEME VARIANTS */
const HomepageSliceZonesWrapper = styled.div`
  ${SliceZonesWrapperBaseMobileStyle};

  & > div.quicklinks {
    h1 {
      display: none;
    }
  }

  ${globalStyles.mq[1]} {
    ${SliceZonesWrapperBaseTableLandscapeStyle}
    margin: unset;
    max-width: unset;
    align-items: flex-end;

    & > div.caroussel {
      flex-basis: 56rem;
      flex-grow: 2;
      flex-shrink: 1;
    }

    & > div.quicklinks {
      position: absolute;
      bottom: 65%;
      left: 60%;
      background-color: ${globalStyles.colors.black};

      span {
        display: flex;
        flex-flow: column;
        h1 {
          display: inherit;
          align-self: center;
          margin: 0;
          padding: 0.8rem 1.5rem;
          color: ${globalStyles.colors.white};
        }
      }
    }

    & > div.text {
      flex-basis: 28rem;
      flex-grow: 1;
      flex-shrink: 2;
    }
  }

  ${globalStyles.mq[2]} {
    & > div.quicklinks {
      bottom: 65%;
    }
  }

  ${globalStyles.mq[3]} {
    & > div.quicklinks {
      bottom: 25%;
    }
  }
`

const HomepageFooterWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex-basis: 100%;
  margin: ${globalStyles.grid.marginBase};
`

/* DEFAULT PAGE THEME VARIANT */
const DefaultpageSliceZonesWrapper = styled.div`
  ${SliceZonesWrapperBaseMobileStyle};

  & > div.form-wrapper {
    margin: 3rem;
  }

  ${globalStyles.mq[1]} {
    ${SliceZonesWrapperBaseTableLandscapeStyle}
    background-color: unset;

    & > div.caroussel,
    & > div.form-wrapper {
      flex-basis: 60%;
      flex-grow: 2;
      flex-shrink: 1;
    }

    & > div.form-wrapper {
      align-self: flex-start;
    }

    & > div.quote,
    & > aside {
      flex-basis: 30%;
      flex-grow: 1;
      flex-shrink: 2;
    }

    & > div.quote {
      order: -10;
    }

    & > div.brands {
      flex-basis: 100%;
      flex-grow: 1;
      align-self: flex-end;
    }
  }
`

/* TYPES PAGE THEME VARIANT */
const TypespageSliceZonesWrapper = styled.div`
  ${SliceZonesWrapperBaseMobileStyle};

  ${globalStyles.mq[1]} {
    ${SliceZonesWrapperBaseTableLandscapeStyle}
    & > div.text {
      flex-basis: 100%;
    }
    & > div.caroussel.block-1x1 {
      flex-basis: 50%;
    }
    & > div.caroussel.block-3x1 {
      flex-basis: 100%;
    }
    & > div.banner_with_caption {
      flex-basis: 100%;
    }
    & > div.qoute {
      flex-basis: 30%;
      flex-grow: 1;
      flex-shrink: 2;
      order: -10;
    }
    & > div.text {
      flex-basis: 60%;
      flex-grow: 2;
      flex-shrink: 1;
    }

    & > div.brands {
      flex-basis: 100%;
      flex-grow: 1;
      align-self: flex-end;
    }
  }
`

/* DIVIDER PAGE THEME VARIANT */
const DividerSliceZonesWrapper = styled.div`
  ${SliceZonesWrapperBaseMobileStyle};

  ${globalStyles.mq[1]} {
    ${SliceZonesWrapperBaseTableLandscapeStyle}
    margin: unset;
    max-width: unset;
    max-height: 51rem;
    overflow-y: hidden;

    & > div.banner_with_caption {
      flex-basis: 100%;
    }

    & > div.address {
      flex-basis: 100%;
    }
  }
`

const SliceZonesWrapper = styled.div`
  ${SliceZonesWrapperBaseMobileStyle};
`

const SpaZone = ({ uid, pageTheme, allSlices, location }) => {
  let spazone
  const pageThemeParam = changeCase.paramCase(pageTheme)

  const sliceZones = allSlices.map(slice => {
    if (slice.id !== undefined)
      return (
        <SliceZone key={slice.id} slice={slice} pageTheme={pageThemeParam} />
      )
    else return null
  })

  switch (pageThemeParam) {
    case "home-page":
      spazone = (
        <div>
          <HomepageSliceZonesWrapper>{sliceZones}</HomepageSliceZonesWrapper>
        </div>
      )
      break
    case "default-page":
      spazone = (
        <div>
          <DefaultpageSliceZonesWrapper>
            {sliceZones}
          </DefaultpageSliceZonesWrapper>
        </div>
      )
      break
    case "contact-page":
      spazone = (
        <div>
          <DefaultpageSliceZonesWrapper>
            <div className="form-wrapper">
              <div>
                <p>
                  Neem contact op of breng een bezoek aan onze toonzaal en breng
                  uw (ver)bouw plannen mee. We bieden professioneel advies en
                  helpen u graag verder!
                </p>
              </div>
              <div className="form">
                <ContactForm location={location} />
              </div>
            </div>
            <aside>{sliceZones}</aside>
          </DefaultpageSliceZonesWrapper>
        </div>
      )
      break
    case "types-page":
      spazone = (
        <TypespageSliceZonesWrapper>
          {sliceZones}
          {uid === "pellets" && (
            <aside>
              <div className="form-wrapper">
                <div>
                  <h3>Bestel uw pellets online!</h3>
                </div>
                <div className="form">
                  <OrderForm location={location} />
                </div>
              </div>
            </aside>
          )}
        </TypespageSliceZonesWrapper>
      )
      break
    case "divider":
      spazone = (
        <DividerSliceZonesWrapper>{sliceZones}</DividerSliceZonesWrapper>
      )
      break
    default:
      spazone = <SliceZonesWrapper>{sliceZones}</SliceZonesWrapper>
      break
  }

  return <SpaZoneSection id={uid}>{spazone}</SpaZoneSection>
}

SpaZone.propTypes = {
  allSlices: PropTypes.array.isRequired,
}

export default SpaZone
