import * as Yup from "yup"

const validationSchema = Yup.object().shape({
  naam: Yup.string()
    .max(50, "Het maximaal aantal karakters bedraagt 50")
    .required("Naam is verplicht"),
  straatNummer: Yup.string()
    .max(80, "Het maximaal aantal karakters bedraagt 80")
    .required("Straat & Nr is verplicht"),
  postcodeGemeente: Yup.string()
    .max(80, "Het maximaal aantal karakters bedraagt 80")
    .required("Postcode & Gemeente is verplicht"),
  telefoon: Yup.string().required("Telefoon is verplicht"),
  email: Yup.string()
    .email("Geen geldig e-mail adres")
    .required("E-mail is verplicht"),
  paletten: Yup.string().required("Aantal paletten is verplicht"),
})

export default validationSchema
