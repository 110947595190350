import styled from "@emotion/styled"
import globalStyles from "../../global-styles"

const BannerWrapper = styled.div`
  position: relative;
`

const CaptionWrapper = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  color: ${globalStyles.colors.white};
  padding: ${globalStyles.grid.marginBase};

  h1 {
    display: inline;
    padding: 0 0.5em;
    line-height: 1.5em;
    color: ${globalStyles.colors.white};
    background-color: ${globalStyles.colors.black};
    overflow: hidden;
    white-space: nowrap;
  }

  span {
    position: relative;
  }

  p {
    font-size: 1.8em;
  }

  ${globalStyles.mq[1]} {
    width: ${globalStyles.grid.container.maxWidth};
    max-width: 100vw;
    left: 50%;
    transform: translate(-50%, 0);
  }
`

const BannerElementWrapper = styled.div`
  display: block;
  position: relative;
`

const BannerElementOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.4);
`

export {
  BannerWrapper,
  CaptionWrapper,
  BannerElementWrapper,
  BannerElementOverlay,
}
