import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import styled from '@emotion/styled';

const mapStyles = {
	position: 'relative',
	width: '100%',
	height: '51rem'
};

const containerStyle = {
	position: 'static'
};

const MapWrapper = styled.div`
	.gmnoprint {
		display: none !important;
	}
`;

const GMap = ({ google, latitude, longitude }) => {
	return (
		<MapWrapper>
			<Map
				google={google}
				style={mapStyles}
				containerStyle={containerStyle}
				zoom={15}
				initialCenter={{ lat: latitude, lng: longitude }}
			>
				<Marker position={{ lat: latitude, lng: longitude }} />
			</Map>
		</MapWrapper>
	);
};

export default GoogleApiWrapper({
	// apiKey: 'AIzaSyASus-aW1bVtmFib-V_-UR_eVA77cBi6pE' // https://console.cloud.google.com/home/dashboard?project=stefaanwillemsweb&folder=&organizationId=
	apiKey: 'AIzaSyAprTeZD_UocIYGZmz_Omf5pLiSqOlhw_M' // Tijdelijke DEV Key omwille van quota berperking map laden per dag - https://console.cloud.google.com/google/maps-apis/apis/maps-backend.googleapis.com/credentials?project=paperview-165213
})(GMap);
