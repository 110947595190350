import React from "react"
import styled from "@emotion/styled"

const ScrollWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;

  span.mouse-icon-caption {
    max-width: 100px;
    font-size: 0.9em;
    text-transform: uppercase;
    align-self: center;
    text-align: center;
    margin-top: 0.8rem;
    font-weight: 800;
  }
`

const HomepageFooter = () => {
  return (
    <section>
      <ScrollWrapper>
        <span className="mouse-icon-caption">benieuwd?</span>
      </ScrollWrapper>
    </section>
  )
}

export default HomepageFooter
