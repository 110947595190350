import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const SEO = ({ title, description }) => {
  const data = useStaticQuery(graphql`
    query {
      prismicBranding {
        data {
          title {
            text
          }
          tagline {
            text
          }
          description {
            text
          }
          site_url {
            url
          }
        }
      }
    }
  `)

  const {
    prismicBranding: {
      data: {
        title: { text: defaultTitle },
        tagline: { text: defaultTagline },
        description: { text: defaultDescription },
        site_url: { url: defaultSiteURL },
      },
    },
  } = data

  const seo = {
    title: title || `${defaultTitle} | ${defaultTagline}`,
    description: description || defaultDescription,
    url: `${defaultSiteURL}`,
  }

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
}
