import React from 'react';
import PropTypes from 'prop-types';
import GMap from './gmap';
import { BannerWrapper, CaptionWrapper, BannerElementWrapper } from './banner-styles';

const BannerMaps = ({ latitude, longitude, caption }) => {
	return (
		<BannerWrapper>
			<CaptionWrapper>
				<span dangerouslySetInnerHTML={{ __html: caption }} />
			</CaptionWrapper>
			<BannerElementWrapper>
				<GMap latitude={latitude} longitude={longitude} />
			</BannerElementWrapper>
		</BannerWrapper>
	);
};

export default BannerMaps;

BannerMaps.propTypes = {
	latitude: PropTypes.number,
	longitude: PropTypes.number,
	caption: PropTypes.string
};
