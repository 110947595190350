import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import globalStyles from "../../global-styles"

const TextWrapper = styled.div`
  margin: ${globalStyles.grid.marginBase};
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`

const Text = ({ htmlText, pageTheme }) => {
  return (
    <TextWrapper
      className={`${pageTheme} text-wrapper`}
      dangerouslySetInnerHTML={{ __html: htmlText }}
    />
  )
}

export default Text

Text.propTypes = {
  htmlText: PropTypes.string.isRequired,
  pageTheme: PropTypes.string,
}
