import React, { useState } from "react"
import axios from "axios"
import { Formik, Field } from "formik"
import validationSchema from "./validation"
import { setupAxiosOptions } from "../form-functions"
import {
  FormFeedbackWrapper,
  FormSection,
  FormWrapper,
  FormElementWrapper,
  FormValidationElement,
} from "../form-styles"

const OrderForm = () => {
  const [feedbackMsg, setFeedBackMsg] = useState()
  const [feedbackStatus, setFeedBackStatus] = useState()

  return (
    <FormSection>
      {feedbackMsg && (
        <FormFeedbackWrapper>
          <p className={feedbackStatus}>{feedbackMsg}</p>
        </FormFeedbackWrapper>
      )}
      <Formik
        initialValues={{
          formName: "order-pellets",
          botField: "",
          naam: "",
          straatNummer: "",
          postcodeGemeente: "",
          telefoon: "",
          email: "",
          paletten: "",
          gewenstLevermoment: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          axios(setupAxiosOptions(values))
            .then(response => {
              setFeedBackMsg(
                "Bedankt voor uw bestelling we nemen zo spoedig mogelijk contact op!"
              )
              setFeedBackStatus("success")
              resetForm()
              setSubmitting(false)
            })
            .catch(err => {
              setFeedBackMsg(
                "Onze excuses, er is een probleem opgetreden bij het verzenden van uw bestelling. Indien dit probleem zich blijft voordoen neem gerust telefonisch contact op via het nummer 09/357 40 40."
              )
              setFeedBackStatus("error")
              setSubmitting(false)
            })
        }}
        render={({ touched, errors, isSubmitting, handleSubmit }) => (
          <FormWrapper
            name="order-pellets"
            onSubmit={handleSubmit}
            data-netlify="true"
            data-netlify-honeypot="botField"
          >
            <Field type="hidden" name="botField" />
            <FormElementWrapper>
              <label htmlFor="naam">Naam</label>
              <Field type="text" id="naam" name="naam" placeholder="Naam" />
              {touched.naam && errors.naam && (
                <FormValidationElement>{errors.naam}</FormValidationElement>
              )}
            </FormElementWrapper>
            <FormElementWrapper>
              <label htmlFor="straatNummer">Straat & Nr</label>
              <Field
                type="text"
                id="straatNummer"
                name="straatNummer"
                placeholder="Straat & Nr"
              />
              {touched.straatNummer && errors.straatNummer && (
                <FormValidationElement>
                  {errors.straatNummer}
                </FormValidationElement>
              )}
            </FormElementWrapper>
            <FormElementWrapper>
              <label htmlFor="postcodeGemeente">Postcode & Gemeente</label>
              <Field
                type="text"
                id="postcodeGemeente"
                name="postcodeGemeente"
                placeholder="Postcode & Gemeente"
              />
              {touched.postcodeGemeente && errors.postcodeGemeente && (
                <FormValidationElement>
                  {errors.postcodeGemeente}
                </FormValidationElement>
              )}
            </FormElementWrapper>
            <FormElementWrapper>
              <label htmlFor="telefoon">Telefoon</label>
              <Field
                type="text"
                id="telefoon"
                name="telefoon"
                placeholder="Telefoon"
              />
              {touched.telefoon && errors.telefoon && (
                <FormValidationElement>{errors.telefoon}</FormValidationElement>
              )}
            </FormElementWrapper>
            <FormElementWrapper>
              <label htmlFor="email">E-mail</label>
              <Field type="text" id="email" name="email" placeholder="E-mail" />
              {touched.email && errors.email && (
                <FormValidationElement>{errors.email}</FormValidationElement>
              )}
            </FormElementWrapper>
            <FormElementWrapper>
              <label htmlFor="paletten">Aantal paletten</label>
              <Field component="select" id="paletten" name="paletten">
                <option defaultValue>Aantal paletten</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </Field>
              {touched.paletten && errors.paletten && (
                <FormValidationElement>{errors.paletten}</FormValidationElement>
              )}
            </FormElementWrapper>
            <FormElementWrapper>
              <label htmlFor="gewenstLevermoment">
                Gewenst levermoment (dag/tijdstip)
              </label>
              <Field
                type="text"
                id="gewenstLevermoment"
                name="gewenstLevermoment"
                placeholder="Gewenst levermoment (dag/tijdstip)"
              />
            </FormElementWrapper>
            <FormElementWrapper>
              <button type="submit" disabled={isSubmitting}>
                Verzenden
              </button>
            </FormElementWrapper>
          </FormWrapper>
        )}
      />
    </FormSection>
  )
}

export default OrderForm
