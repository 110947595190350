import React from "react"
import { Helmet } from "react-helmet"

const Head = props => {
  return (
    <Helmet title={`${props.title} | ${props.tagline}`}>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
      />
    </Helmet>
  )
}

export default Head
