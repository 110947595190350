import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import globalStyles from '../../global-styles';

const BrandsWrapper = styled.div`
	position: relative;
	display: flex;
	flex-flow: column;
	justify-content: center;

	span.component-title {
		align-self: center;
	}
	ul {
		display: flex;
		flex-flow: column;
		justify-content: center;
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			text-align: center;
			margin-block-end: 3rem;

			&:last-child {
				margin-block-end: unset;
			}
		}
	}

	${globalStyles.mq[1]} {
		ul {
			flex-flow: row;
			align-items: center;

			li {
				margin: 0 3rem 0 0;

				&:last-child {
					margin: 0;
				}
			}
		}
	}
`;

const Brands = ({ items }) => {
	return (
		<BrandsWrapper>
			<span className="component-title">
				<h3>Onze merken</h3>
			</span>
			<ul>
				{items.map(({ brand: { document: [{ id, data }] } }) => {
					if (data.image.localFile.childImageSharp != null) {
						return (
							<li key={id}>
								<a href={data.link.url}>
									<Img fixed={data.image.localFile.childImageSharp.fixed} alt={data.title.text} />
								</a>
							</li>
						);
					} else {
						return null;
					}
				})}
			</ul>
		</BrandsWrapper>
	);
};

export default Brands;

Brands.propTypes = {
	items: PropTypes.array.isRequired
};
