import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import globalStyles from '../../global-styles';

const QuoteWrapper = styled.div`
	display: block;
	margin: 6rem ${globalStyles.grid.marginBase};

	blockquote {
		position: relative;
		text-align: center;
		margin-block-start: 2rem;
		margin-block-end: 2rem;
		margin-inline-start: 6rem;
		margin-inline-end: 6rem;

		&:before,
		&:after {
			font-family: 'FontAwesome';
			position: absolute;
			top: 35%;
			color: ${globalStyles.colors.lightGrey};
			font-size: 4em;
			line-height: 0.1em;
		}

		&:before {
			content: '\f10d';
			margin-right: 1.3rem;
			right: 100%;
		}

		&:after {
			content: '\f10e';
			margin-left: 1.3rem;
			left: 100%;
		}

		h3,
		h4,
		h5,
		h6 {
			color: ${globalStyles.colors.blue};
			margin: 0;
		}
	}

	${globalStyles.mq[1]} {
		background-color: ${globalStyles.colors.white};
		blockquote {
			color: ${globalStyles.colors.black};
		}
	}
`;

const Quote = ({ htmlText, pageTheme }) => (
	<QuoteWrapper className={pageTheme}>
		<blockquote>
			<div dangerouslySetInnerHTML={{ __html: htmlText }} />
		</blockquote>
	</QuoteWrapper>
);

export default Quote;

Quote.propTypes = {
	htmlText: PropTypes.string.isRequired,
	pageTheme: PropTypes.string
};
