import React from "react"
import styled from "@emotion/styled"
import globalStyles from "../../global-styles"

const VideoComponentWrapper = styled.div`
  display: block;
  width: 426px;
  height: 240px;
  ${globalStyles.mq[0]} {
    width: 640px;
    height: 360px;
  }

  ${globalStyles.mq[1]} {
    width: 854px;
    height: 480px;
  }
`

const VideoWrapper = styled.div`
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`

const Video = ({ videoSrcURL, videoTitle }) => {
  return (
    <VideoComponentWrapper>
      <VideoWrapper>
        <iframe
          src={videoSrcURL}
          title={videoTitle}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </VideoWrapper>
    </VideoComponentWrapper>
  )
}

export default Video
