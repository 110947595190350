import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import {
  BannerWrapper,
  CaptionWrapper,
  BannerElementWrapper,
  BannerElementOverlay,
} from "./banner-styles"

const imgStyle = {
  minHeight: "225px",
  //picture: { img: { objectPosition: 'right center !important' } }
}

const BannerWithCaptions = ({ image, caption }) => {
  return (
    <BannerWrapper>
      <CaptionWrapper>
        <span dangerouslySetInnerHTML={{ __html: caption }} />
      </CaptionWrapper>
      <BannerElementWrapper>
        <Img
          alt={image.alt}
          style={imgStyle}
          fluid={image.localFile.childImageSharp.fluid}
        />
        <BannerElementOverlay />
      </BannerElementWrapper>
    </BannerWrapper>
  )
}

export default BannerWithCaptions

BannerWithCaptions.propTypes = {
  image: PropTypes.object.isRequired,
  caption: PropTypes.string.isRequired,
}
