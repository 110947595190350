import styled from "@emotion/styled"
import globalStyles from "../global-styles"

const FormSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
`

const FormFeedbackWrapper = styled.div`
  display: block;
  margin: ${globalStyles.grid.marginBase} 0;

  p {
    font-size: 1.6em;
    font-weight: ${globalStyles.fonts.base.fontWeightBold};
    width: 100%;
    padding: ${globalStyles.grid.marginBase};
    border-radius: 1rem;

    &.success {
      background-color: ${globalStyles.colors.green};
      color: ${globalStyles.colors.darkGreen};
    }

    &.error {
      background-color: ${globalStyles.colors.red};
      color: ${globalStyles.colors.darkRed};
    }
  }
`

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`

const FormElementWrapper = styled.span`
  label {
    display: none;
  }
  input,
  textarea,
  select {
    margin-top: ${globalStyles.grid.marginBase};
    width: 100%;
    font-size: 1.8em;
    color: ${globalStyles.colors.blue};
    border: none;
    border-bottom-color: ${globalStyles.colors.blue};
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding: 0 2rem 1rem;
    &::placeholder {
      color: ${globalStyles.colors.black};
    }
  }
  input {
    height: 4.2rem;
  }
  textarea {
    height: 12rem;
  }
  select {
    color: ${globalStyles.colors.black};
  }
  button {
    margin-top: 3rem;
    color: ${globalStyles.colors.white};
    background-color: ${globalStyles.colors.blue};
    font-size: 1.8rem;
    line-height: 1.8;
    padding: 1.5rem 5rem;
    text-transform: uppercase;
    font-weight: ${globalStyles.fonts.base.fontWeightSemiBold};
    border: none;
    transition: all 0.2s ease;
    &:hover {
      background-color: ${globalStyles.colors.black};
    }
  }
`

const FormValidationElement = styled.p`
  font-size: 1.4em;
  width: 100%;
  padding: 1rem;
  color: ${globalStyles.colors.errorRed};
`

export {
  FormFeedbackWrapper,
  FormSection,
  FormWrapper,
  FormElementWrapper,
  FormValidationElement,
}
