import React from 'react';
import PropTypes from 'prop-types';
import { Brands, Carousel, BannerWithCaptions, Quicklinks, Quote, Text, BannerMap } from './slices';
import styled from '@emotion/styled';
import changeCase from 'change-case';
import globalStyles from '../global-styles';

const SliceZoneWrapper = styled.div`
	margin: 0;
`;

const SliceZone = ({ slice, slice: { items = [] }, pageTheme }) => {
	let slicezone;
	let caption = '';
	let html = '';
	let className = slice.slice_type;
	if (slice.primary !== undefined) {
		if (slice.primary.caption !== undefined) caption = slice.primary.caption.html;
		if (slice.primary.text !== undefined) html = slice.primary.text && slice.primary.text.html;
	}
	switch (slice.slice_type) {
		case 'brands':
			slicezone = <Brands items={items} />;
			break;
		case 'caroussel':
			let carouselVariant =
				slice.primary.carousel_variant === null
					? 'default'
					: changeCase.paramCase(slice.primary.carousel_variant);

			let htmlTextAlign =
				slice.primary.text_block_align === null ? 'left' : changeCase.paramCase(slice.primary.text_block_align);

			className = `${slice.slice_type} ${carouselVariant}`;
			slicezone = (
				<Carousel
					items={items}
					carouselVariant={carouselVariant}
					caption={caption}
					htmlText={html}
					htmlTextAlign={htmlTextAlign}
					pageTheme={pageTheme}
				/>
			);
			break;
		case 'banner_with_caption':
			if (slice.primary.image !== undefined) {
				let image = slice.primary.image;
				slicezone = <BannerWithCaptions image={image} caption={caption} />;
			}
			break;
		case 'quicklinks':
			slicezone = <Quicklinks items={items} caption={caption} />;
			break;
		case 'quote':
			const {
				primary: {
					quote_text: { html: quoteHtmlText }
				}
			} = slice;
			slicezone = <Quote htmlText={quoteHtmlText} pageTheme={pageTheme} />;
			break;
		case 'text':
			slicezone = <Text htmlText={html} pageTheme={pageTheme} />;
			break;
		case 'address':
			const { latitude, longitude } = slice.primary.geopoint;
			slicezone = <BannerMap latitude={latitude} longitude={longitude} caption={caption} />;
			break;
		default:
			slicezone = null;
			break;
	}

	return <SliceZoneWrapper className={className}>{slicezone}</SliceZoneWrapper>;
};

SliceZone.propTypes = {
	slice: PropTypes.object
};

export default SliceZone;
