import React from "react"
import styled from "@emotion/styled"
import { Link, animateScroll as scroll } from "react-scroll"
import PropTypes from "prop-types"
import globalStyles from "../../global-styles"

const QuicklinksCaption = styled.span`
  display: block;
`

const QuicklinksSection = styled.section`
  ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: ${globalStyles.colors.blue};
    justify-content: space-evenly;

    li {
      font-size: 1.1em;

      ${globalStyles.mq[1]} {
        font-size: 1.2em;
      }

      font-weight: ${globalStyles.fonts.base.fontWeightBold};
      text-transform: uppercase;
      line-height: 2.8em;
      margin: 0;
      padding: 0 0.4em;

      a {
        color: ${globalStyles.colors.white};
        cursor: pointer;
      }
    }
  }
`

const Quicklinks = ({ items, pageTheme, caption }) => {
  return (
    <QuicklinksSection>
      {pageTheme === "homePage" ? <h1>Set page title</h1> : null}
      <QuicklinksCaption dangerouslySetInnerHTML={{ __html: caption }} />
      <ul>
        {items.map(item => {
          return (
            <li key={item.quicklink_slug}>
              <Link
                activeClass="active"
                className={item.quicklink_slug}
                to={item.quicklink_slug}
                spy={true}
                smooth={true}
                duration={500}
              >
                <span>{item.quicklink_label.text}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </QuicklinksSection>
  )
}

export default Quicklinks

Quicklinks.propTypes = {
  items: PropTypes.array.isRequired,
  pageTheme: PropTypes.string,
}
